import Vue from 'vue';
import Vuex from 'vuex';

import core from './core';
import user from './user';
import router from './router';
import alert from './alert';
import devices from './devices';
import display from './display';
import download from './download';
import stat from './stat';
import config from './config';
import location from './location';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    core,
    user,
    router,
    alert,
    devices,
    display,
    download,
    stat,
    config,
    location,
  },
});
