<template>
  <layout-dashboard-card
    title="운행 종료 차량"
    :value="count"
    append-string="대"
    :icon="icon"
    :width="350"
    :height="180">
  </layout-dashboard-card>
</template>

<script>
import LayoutDashboardCard from '@/components/card/LayoutAccentCard.vue';
import Icon from '@/assets/icon_03.svg';
import { mapGetters } from 'vuex';

export default {
  name: 'CardTotalStop',
  components: {
    LayoutDashboardCard,
  },
  data: () => ({
    icon: Icon,
  }),
  computed: {
    ...mapGetters({
      devices: 'devices/devices',
    }),
    count() {
      return this.devices.reduce(
        (acc, item) => (acc + ((item.stat === 'connected' && item.park === 0) ? 0 : 1)),
        0,
      );
    },
  },
};
</script>

<style scoped>

</style>
