export default {
  namespaced: true,
  state: {
    initialized: false,
    path: null,
    lastPath: '/',
    back: false,
  },
  getters: {
    path(state) {
      return state.path;
    },
    lastPath(state) {
      return state.lastPath;
    },
    back(state) {
      return state.back;
    },
  },
  mutations: {
    lastPath(state, value) {
      if (!state.initialized) {
        state.initialized = true;
        state.lastPath = value;
      }
    },
  },
  actions: {
    push({ state }, value) {
      state.path = value;
    },
    pushed({ state }) {
      state.path = null;
    },
    back({ state }) {
      state.back = true;
    },
    backed({ state }) {
      state.back = false;
    },
  },
};
