<template>
  <layout-dashboard-card
    title="총 데이터 사용량"
    :value="toValue(statDataUsage.total[0])"
    prepend-string="오늘"
    :append-string="toUnit(statDataUsage.total[0])"
    :icon="icon"
    :width="350"
    :height="180">
    <template v-slot:extension>
      <div class="card-history-list d-flex justify-center">
        <table>
          <tr
            v-for="idx in 3"
            :key="`value_${idx}`">
            <td>{{ idx }}일전</td>
            <td class="card-history-list-value">
              {{ toValue(statDataUsage.total[idx]) }} {{ toUnit(statDataUsage.total[idx]) }}
            </td>
          </tr>
        </table>
      </div>
    </template>
  </layout-dashboard-card>
</template>

<script>
import LayoutDashboardCard from '@/components/card/LayoutAccentCard.vue';
import Icon from '@/assets/icon_09.svg';
import { mapGetters } from 'vuex';

export default {
  name: 'CardTotalDataUsage',
  components: {
    LayoutDashboardCard,
  },
  data: () => ({
    icon: Icon,
  }),
  computed: {
    ...mapGetters({
      statDataUsage: 'stat/data',
    }),
  },
  methods: {
    toValue(usage) {
      let ret = usage / 1024;

      if (ret > 1024) {
        ret /= 1024;
      }

      ret = Math.round(ret * 10) / 10;

      return (usage > 0) ? ret : '-';
    },
    toUnit(usage) {
      const ret = usage / 1024;
      let unit = 'MB';

      if (ret > 1024) {
        unit = 'GB';
      }

      return unit;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-history-list {
  color: #9a9a9a;

  p {
    margin-bottom: 0;
  }

  table {
    .card-history-list-value {
      text-align: right;
      min-width: 64px;
    }
  }
}
</style>
