import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    id: '',
    live: {},
    dataConfirm: {
      flag: false,
    },
  },
  getters: {
    live(state) {
      return state.live;
    },
    dataConfirm(state) {
      return state.dataConfirm.flag;
    },
  },
  mutations: {
    id(state, id) {
      state.id = id;
    },
    live(state, value) {
      console.log('mutation.live', value);
      state.live = {
        ...state.live,
        ...value,
      };

      Vue.$ls.set(`config.live.${state.id}`, JSON.stringify(state.live));
    },
    dataConfirm(state, value) {
      state.dataConfirm = {
        flag: value.config.flag,
      };

      if (value.commit == null || value.commit) {
        // 유효기간은 금일 자정까지로 설정
        const now = new Date();
        const tomorrow = new Date();
        tomorrow.setHours(0, 0, 0, 0);
        tomorrow.setDate(tomorrow.getDate() + 1);

        Vue.$ls.set(
          `config.data_confirm.${state.id}`,
          JSON.stringify(state.dataConfirm),
          tomorrow.getTime() - now.getTime(),
        );
      }
    },
  },
  actions: {
    initialize({ commit }, id) {
      commit('id', id);

      // Live 설정 가져오기
      const rawConfigLive = Vue.$ls.get(`config.live.${id}`);
      const configLive = (rawConfigLive != null)
        ? JSON.parse(rawConfigLive)
        : { selected: [] };

      commit('live', configLive);
      commit(
        'devices/liveSelected',
        configLive.selected,
        { root: true },
      );

      // 데이터 사용 경고 알림 보기 설정
      const rawConfigDataConfirm = Vue.$ls.get(`config.data_confirm.${id}`);
      const configDataConfirm = (rawConfigDataConfirm != null)
        ? JSON.parse(rawConfigDataConfirm)
        : { flag: false };

      commit('dataConfirm', { config: configDataConfirm, commit: false });

      console.log('config.initialize:', id, configLive, configDataConfirm);
    },
  },
};
