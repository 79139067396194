export default {
  namespaced: true,
  state: {
    alert: null,
  },
  getters: {
    alert(state) {
      return state.alert;
    },
  },
  mutations: {
  },
  actions: {
    setAlert({ state }, data) {
      state.alert = data;
    },
  },
};
