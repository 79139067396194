<template>
  <v-card
    flat
    tile
    :width="width"
    :height="height"
    :min-width="minWidth"
    :max-width="maxWidth">
    <v-card-title>
      <slot name="title">
        {{ title }}
        <v-spacer>
        </v-spacer>
        <v-btn
          v-if="showMore"
          class="btn-more"
          color="secondary"
          small
          rounded
          icon
          outlined>
          <v-icon
            size="20">
            mdi-arrow-right-bold
          </v-icon>
        </v-btn>
      </slot>
    </v-card-title>
    <v-divider>
    </v-divider>
    <v-card-text
      :style="{ height: `${bodyHeight}px` }"
      class="pa-3">
      <slot name="body"></slot>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'LayoutCard',
  props: {
    title: {
      type: String,
      default: 'Title',
    },
    showMore: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    prependString: {
      type: String,
      default: null,
    },
    appendString: {
      type: String,
      default: null,
    },
    width: {
      type: Number,
      default: null,
    },
    height: {
      type: Number,
      default: null,
    },
    minWidth: {
      type: Number,
      default: 300,
    },
    maxWidth: {
      type: Number,
      default: null,
    },
  },
  computed: {
    bodyHeight() {
      return (this.height != null) ? (this.height - 34) : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  height: 100%;

  .v-card__title {
    color: #0A6FBD;
    font-size: 12pt;
    font-weight: 700;
    padding-top: 3pt;
    padding-bottom: 3pt;
  }

  .v-divider {
    border-color: #c5c5c5;
  }

  .btn-more {
    border-radius: 4px;
  }
}
</style>
