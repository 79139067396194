import { render, staticRenderFns } from "./CardRouterStat.vue?vue&type=template&id=6d457dc3&scoped=true&"
import script from "./CardRouterStat.vue?vue&type=script&lang=js&"
export * from "./CardRouterStat.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d457dc3",
  null
  
)

export default component.exports