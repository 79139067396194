<template>
  <div
    class="d-flex flex-column flex-grow-1"
    :style="{
      minWidth: `${minWidth}px`,
      height: '100%',
    }">
    <div
      class="av-container mb-1 d-flex justify-space-between align-content-end">
      <div class="av-display-string">
        {{ prependString }}
      </div>
      <div class="av-display-value">
        {{ value }}
      </div>
      <div class="av-display-string">
        {{ appendString }}
      </div>
    </div>
    <v-divider>
    </v-divider>
    <div>
      <slot name="extension">
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccentValue',
  props: {
    value: {
      type: [Number, String],
      default: 0,
    },
    prependString: {
      type: String,
      default: '',
    },
    appendString: {
      type: String,
      default: '',
    },
    minWidth: {
      type: Number,
      default: 96,
    },
  },
};
</script>

<style lang="scss" scoped>
.av-container {
  line-height: normal;
  padding-left: 1rem;
  padding-right: 1rem;

  .av-display-string {
    margin-top: auto;
  }

  .av-display-value {
    font-size: 3rem;
    color: #31b9ef;
  }
}
</style>
