<template>
  <v-snackbar
    :value="showDownloadState"
    color="primary"
    top
    right
    timeout="-1">
    <v-card
      flat
      color="primary"
      width="100%">
      <v-card-title
        class="stat-title">
        <span>다운로드 상태</span>
        <v-spacer>
        </v-spacer>
        <v-btn
          @click="showDownloadState = !showDownloadState"
          icon
          small>
          <v-icon
            small>
            mdi-window-minimize
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text
        class="stat-body">
        <v-list
          color="primary">
          <template
            v-for="(item, idx) in downloads">
            <v-list-item
              three-line
              :key="`item_${idx}`">
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.fileName }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ item.serial }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  {{ Math.ceil(item.current / 1024) }}KB
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  :href="`${base}${item.downloadPath}`"
                  target="_blank"
                  download
                  icon
                  :loading="!item.done">
                  <v-icon>
                    mdi-download
                  </v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list>
      </v-card-text>
    </v-card>
  </v-snackbar>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'DownloadStat',
  computed: {
    ...mapGetters({
      downloads: 'download/downloads',
      downloadShow: 'download/show',
    }),
    showDownloadState: {
      get() {
        return this.downloadShow;
      },
      set(value) {
        this.$store.dispatch('download/showState', value);
      },
    },
  },
  data: () => ({
    base: 'https://bbnms.cns-link.net:4443',
  }),
};
</script>

<style lang="scss" scoped>
.stat-title {
  padding: 0 0 1rem;
  font-size: 1rem;
}

.stat-body {
  padding: 0;
}
</style>
