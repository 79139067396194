export default {
  namespaced: true,
  state: {
    size: {
      width: 0,
      height: 0,
    },
  },
  getters: {
    size(state) {
      return state.size;
    },
  },
  mutations: {
    size(state, value) {
      state.size = value;
    },
  },
  actions: {
    resize({ commit }) {
      commit('size', {
        width: window.innerWidth,
        height: window.innerHeight,
      });
    },
  },
};
