import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    rawDevices: [],
    devices: [],
    select: 0,
    getDevicesTimer: null,
    liveSelected: [],
    liveLimit: 9,
  },
  getters: {
    rawDevices(state) {
      return state.rawDevices;
    },
    devices(state) {
      return state.devices;
    },
    select(state) {
      return state.select;
    },
    selectedDevice(state) {
      return state.devices[state.select];
    },
    liveSelected(state) {
      return state.liveSelected;
    },
    liveItems(state) {
      const items = [];
      const liveSelected = [...state.liveSelected];

      for (let idx = 0; idx < state.devices.length; idx += 1) {
        const found = liveSelected.findIndex(
          (serial) => (state.devices[idx].serial === serial),
        );
        if (found >= 0) {
          items.push(state.devices[idx]);
          liveSelected.slice(found, 1);
        }

        if (liveSelected.length <= 0) {
          break;
        }
      }

      return items;
    },
    liveLimit(state) {
      return state.liveLimit;
    },
  },
  mutations: {
    rawDevices(state, items) {
      state.rawDevices = items.map((item) => ({
        serial: item.serial,
        name: item.name,
        temp_flag: item.temp_flag,
      }));
    },
    devices(state, items) {
      state.devices = items.map((item) => {
        let gps;

        try {
          gps = JSON.parse(item.gps);
        } catch (error) {
          gps = null;
        }

        return {
          ...item,
          gps,
        };
      });
    },
    select(state, index) {
      state.select = index;
    },
    liveSelected(state, selected) {
      state.liveSelected = selected;
    },
  },
  actions: {
    'socket.term.device.list.get.mgr': ({ commit, dispatch }, data) => {
      if (data.result === 'success') {
        commit('rawDevices', data.items);
        commit('devices', data.items.filter((item) => (item.temp_flag === 0)));
      } else {
        dispatch('alert/setAlert', {
          message: data.message,
          color: 'error',
        }, { root: true });
      }
    },
    getDevices() {
      Vue.$socket.emit('term.device.list.get.mgr', {});
    },
    startGetDevicesTimer({ state, dispatch }) {
      state.getDevicesTimer = setInterval(() => {
        dispatch('getDevices');
      }, 30000);
    },
    stopGetDevicesTimer({ state }) {
      clearInterval(state.getDevicesTimer);
    },
    updateLiveSelected({ commit }, selected) {
      commit('liveSelected', selected);
      commit('config/live', { selected }, { root: true });
    },
  },
};
