<template>
  <v-app
    v-resize="onResize">
    <v-app-bar
      height="54"
      color="white"
      fixed
      flat
      app
      dense>
      <v-app-bar-title>
          <strong>마이딘 통신형 블랙박스 관제시스템</strong>
      </v-app-bar-title>
      <v-spacer></v-spacer>
      <div>
        <v-tabs
          class="app-tab-menu"
          height="36"
          show-arrows
          center-active
          color="secondary">
          <v-tab
            v-for="(item, idx) in selectedMenu"
            :key="`menu_${idx}`"
            :to="item.to">
            {{ item.text }}
          </v-tab>
        </v-tabs>
      </div>
      <div
        v-if="downloads.length > 0">
        <v-btn
          @click="showDownloadState = !showDownloadState"
          :disabled="!loggedIn"
          class="btn-logout"
          small
          outlined
          icon>
          <v-icon
            size="20">
            mdi-download
          </v-icon>
        </v-btn>
      </div>
      <div>
        <v-btn
          @click="logout"
          :disabled="!loggedIn"
          class="btn-logout"
          small
          outlined
          icon>
          <v-icon
            size="20">
            mdi-export
          </v-icon>
        </v-btn>
      </div>
    </v-app-bar>
    <v-main>
      <v-container
        fluid>
        <router-view/>
      </v-container>
    </v-main>
    <v-footer
      app
      fixed
      class="pr-3 pl-3 pb-3 pt-0"
      color="#d7d7d7">
      <v-card
        class="footer-card"
        flat
        tile
        width="100%">
        <v-card-text>
          <div class="d-flex flex-row">
            <div class="d-flex ma-auto align-center">
              <v-img
                class="mr-4"
                contain
                :src="footerLogo"
                width="74">
              </v-img>
              <strong>마이딘 통신형 블랙박스 관제 시스템</strong>
            </div>
            <v-spacer>
            </v-spacer>
            <div
              class="hidden-sm-and-down">
              <div class="d-flex flex-column text-left">
                <div>
                  <strong>문의: 1600-0256</strong>
                </div>
                <div>
                  Copyrights (c) CNS-LINK.CO.LTD.,All rights reserved.
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-footer>
    <v-overlay
      :value="!initialized">
      <div class="d-flex flex-column">
        <v-progress-circular
          class="mb-8"
          size="64"
          color="primary"
          indeterminate>
        </v-progress-circular>
        <span>
          <strong>
            초기화중...
          </strong>
        </span>
      </div>
    </v-overlay>
    <v-snackbar
      app
      top
      v-if="showAlert"
      v-model="showAlert"
      timeout="3000"
      :color="alert.color">
      {{ alert.message }}
    </v-snackbar>
    <download-stat>
    </download-stat>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DownloadStat from '@/components/output/DownloadStat.vue';

import FooterLogo from '@/assets/footer_logo.png';

export default {
  name: 'App',
  components: {
    DownloadStat,
  },
  data: () => ({
    footerLogo: FooterLogo,
    menu: {
      control: {
        items: [
          { text: '대시 보드', to: '/' },
          { text: '블랙박스목록', to: '/devicelist' },
          { text: '실시간영상', to: '/live' },
          { text: '지도보기', to: '/location' },
          { text: '운행일지', to: '/' },
          { text: '환경설정', to: '/settings' },
        ],
      },
      manage: {
        items: [
          { text: '블랙박스목록', to: '/devicelistall' },
          { text: '펌웨어', to: '/firmware' },
        ],
      },
    },
  }),
  computed: {
    ...mapGetters({
      initialized: 'core/initialized',
      path: 'router/path',
      lastPath: 'router/lastPath',
      routeBack: 'router/back',
      alert: 'alert/alert',
      loggedIn: 'user/loggedIn',
      userInfo: 'user/userInfo',
      downloads: 'download/downloads',
      downloadShow: 'download/show',
    }),
    selectedMenu() {
      return (this.userInfo && this.userInfo.manage === 'Y')
        ? this.menu.manage.items : this.menu.control.items;
    },
    showAlert: {
      get() {
        return (this.alert != null);
      },
      set() {
        this.$store.dispatch('alert/setAlert', null);
      },
    },
    showDownloadState: {
      get() {
        return this.downloadShow;
      },
      set(value) {
        this.$store.dispatch('download/showState', value);
      },
    },
  },
  watch: {
    path: {
      immediate: true,
      handler(value) {
        if (value != null) {
          // 이전 경로와 같은지 확인
          if (this.$route.path !== value) {
            this.$router.push(value).catch((error) => {
              console.info('Info', error);
            });
          } else if (value === this.lastPath) {
            // 권한이 필요하지 않은 페이지가 lastPath일 경우 '/'로 이동
            const path = (this.$router.match(value).meta.needAuthorization)
              ? value : '/';
            this.$router.push(path).catch((error) => {
              console.info('Info', error);
            });
          } else {
            // this.$router.push(value);
          }
          this.$store.dispatch('router/pushed');
        }
      },
    },
    routeBack: {
      immediate: true,
      handler(value) {
        if (value) {
          console.log('routerBack', this.$router, this.$route);
          this.$router.go(-2);
          this.$store.dispatch('router/backed');
        }
      },
    },
  },
  mounted() {
    console.log('router:', this.$router);
  },
  methods: {
    ...mapActions({
      logout: 'user/logout',
      onResize: 'display/resize',
    }),
  },
};
</script>

<style lang="scss">
html {
  overflow-y: auto !important;
  font-size: 13px !important;
}

#app {
  font-family: 'Nanum Gothic', Roboto, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #303030;
  background-color: #d7d7d7;
  font-size: 14px !important;

  .v-toolbar {
    .v-tabs {
      .v-tabs-bar__content {
        .v-tabs-slider-wrapper {
          height: 5px !important;
          color: #2fb8ec;
        }

        .v-tab {
          font-size: 10pt;
          border-right: #b9b9b9 solid 1px;

          &:nth-child(2) {
            border-left: #b9b9b9 solid 1px;
          }
        }
      }
    }
  }
}

#nav {
  padding: 30px;

  a {
    font-weight: 700;
    color: #2c3e50;
  }
}

.v-data-table {
  tr {
    th {
      padding-right: .25rem !important;
      padding-left: .25rem !important;
    }

    td {
      padding-right: .25rem !important;
      padding-left: .25rem !important;
      font-size: 12px !important;
    }
  }

  .v-label {
    font-size: 12px;
    word-break: keep-all;
  }

  .v-input {
    font-size: 12px !important;
  }
}

.v-app-bar {
  .v-toolbar__content {
    border-bottom: #646464 2px solid;
  }
}
</style>

<style lang="scss" scoped>
.btn-logout {
  margin-left: 1rem;
  border-radius: 4px;
}

.footer-card {
  background-color: #c4c4c4 !important;
  border: #c0c0c0 solid 2px !important;
}

.v-tab {
  font-weight: bold;
}
</style>
