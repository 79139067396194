import Vue from 'vue';
import store from '../store';
import VuePollingComm from './vue-polling-comm';

const host = (process.env.NODE_ENV === 'production')
  ? `https://${window.location.hostname}:4443`
  : 'http://localhost:4000';

Vue.use(new VuePollingComm({
  host,
  vuex: {
    store,
    actionPrefix: 'socket.',
    mutationPrefix: 'socket.',
  },
}));
