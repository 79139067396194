<template>
  <layout-dashboard-card
    title="블랙박스 상태 조회"
    :value="onlineCount"
    append-string="대"
    :icon="icon"
    :width="350"
    :height="180">
    <template v-slot:extension>
      <div class="card-history-list d-flex justify-center">
        <table>
          <tr>
            <td>불량</td>
            <td class="card-history-list-value">{{ offlineCount }} 대</td>
          </tr>
        </table>
      </div>
    </template>
  </layout-dashboard-card>
</template>

<script>
import { mapGetters } from 'vuex';
import LayoutDashboardCard from '@/components/card/LayoutAccentCard.vue';
import Icon from '@/assets/icon_06.svg';

export default {
  name: 'CardTotalOnline',
  components: {
    LayoutDashboardCard,
  },
  data: () => ({
    icon: Icon,
  }),
  computed: {
    ...mapGetters({
      devices: 'devices/devices',
    }),
    onlineCount() {
      return this.devices.reduce(
        (acc, item) => (acc + ((item.stat === 'connected') ? 1 : 0)),
        0,
      );
    },
    offlineCount() {
      return this.devices.length - this.onlineCount;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-history-list {
  color: #9a9a9a;

  p {
    margin-bottom: 0;
  }

  table {
    .card-history-list-value {
      text-align: right;
      width: 48px;
    }
  }
}
</style>
