import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    tasks: [],
    flag: false,
    coordinates: {
      lat: 36.350701,
      lng: 127.670667,
    },
    level: 12,
    locationLog: {
      serial: null,
      items: [],
    },
  },
  getters: {
    tasks(state) {
      return state.tasks;
    },
    flag(state) {
      return state.flag;
    },
    coordinates(state) {
      return state.coordinates;
    },
    level(state) {
      return state.level;
    },
    locationLog(state) {
      return state.locationLog;
    },
  },
  mutations: {
    addTask(state, value) {
      state.tasks.push(value);
    },
    popTask(state) {
      state.tasks.splice(0, 1);
    },
    flag(state, value) {
      console.log('flag comm:', value);
      state.flag = value;
    },
    coordinates(state, value) {
      state.coordinates = value;
    },
    level(state, value) {
      state.level = value;
    },
    locationLog(state, value) {
      state.locationLog = value;
    },
  },
  actions: {
    'socket.term.location.log.get': ({ commit, dispatch }, data) => {
      console.log('location.log:', data);
      if (data.result === 'success') {
        commit('locationLog', {
          serial: data.serial,
          items: data.items,
        });
      } else {
        dispatch('alert/setAlert', {
          message: data.message,
          color: 'error',
        }, { root: true });
      }
    },
    reset({ commit }) {
      commit('addTask', { type: 'level', value: 12 });
      commit('addTask', { type: 'move', value: { lat: 36.350701, lng: 127.670667 } });
      commit('flag', true);
    },
    focus({ commit }, { coordinates, level }) {
      commit('addTask', { type: 'level', value: level });
      commit('addTask', { type: 'move', value: coordinates });

      commit('level', level);
      commit('coordinates', coordinates);

      commit('flag', true);
    },
    flag({ commit }, value) {
      console.log('flag dis', value);
      commit('flag', value);
    },
    getLocationLog(_, value) {
      console.log('getLocationLog:', value);
      Vue.$socket.emit('term.location.log.get', { serial: value.serial });
    },
    resetLocationLog({ commit }) {
      commit('locationLog', {
        serial: null,
        items: [],
      });
    },
  },
};
