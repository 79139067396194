export default {
  namespaced: true,
  state: {
    initialized: false,
  },
  getters: {
    initialized(state) {
      return state.initialized;
    },
  },
  mutations: {
    initialized(state, value) {
      state.initialized = value;
    },
  },
  actions: {
    'socket.connected': ({ dispatch }) => {
      dispatch('user/initialize', null, { root: true });
    },
    'socket.disconnected': ({ commit }) => {
      commit('initialized', false);
      commit('user/loggedIn', false, { root: true });
    },
  },
};
