<template>
  <div
    style="max-width: 1280px; margin: auto;"
    class="d-flex">
    <div
      style="overflow-y: auto; overflow-x: hidden;"
      :style="{ height: `${listHeight - 24}px` }">
      <v-row
        class="dashboard-card-list"
        align="start"
        justify="center">
        <v-col>
          <card-total-device>
          </card-total-device>
        </v-col>
        <v-col>
          <card-total-run>
          </card-total-run>
        </v-col>
        <v-col>
          <card-total-stop>
          </card-total-stop>
        </v-col>
        <v-col>
          <card-total-time>
          </card-total-time>
        </v-col>
        <v-col>
          <card-total-distance>
          </card-total-distance>
        </v-col>
        <v-col>
          <card-total-online>
          </card-total-online>
        </v-col>
        <v-col>
          <card-router-stat>
          </card-router-stat>
        </v-col>
        <v-col>
          <card-total-events>
          </card-total-events>
        </v-col>
        <v-col>
          <card-total-data-usage>
          </card-total-data-usage>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CardTotalDevice from '@/components/card/dashbard/CardTotalDevice.vue';
import CardTotalRun from '@/components/card/dashbard/CardTotalRun.vue';
import CardTotalStop from '@/components/card/dashbard/CardTotalStop.vue';
import CardTotalTime from '@/components/card/dashbard/CardTotalTime.vue';
import CardTotalDistance from '@/components/card/dashbard/CardTotalDistance.vue';
import CardTotalOnline from '@/components/card/dashbard/CardTotalOnline.vue';
import CardRouterStat from '@/components/card/dashbard/CardRouterStat.vue';
import CardTotalEvents from '@/components/card/dashbard/CardTotalEvents.vue';
import CardTotalDataUsage from '@/components/card/dashbard/CardTotalDataUsage.vue';

export default {
  name: 'Home',
  components: {
    CardTotalDevice,
    CardTotalRun,
    CardTotalStop,
    CardTotalTime,
    CardTotalDistance,
    CardTotalOnline,
    CardRouterStat,
    CardTotalEvents,
    CardTotalDataUsage,
  },
  computed: {
    ...mapGetters({
      displaySize: 'display/size',
      statRegistered: 'stat/registered',
      statTime: 'stat/time',
    }),
    listHeight() {
      return this.displaySize.height
        - (this.$vuetify.application.top + this.$vuetify.application.footer);
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard-card-list {
  margin: -4px;

  .col {
    margin: 4px;
    padding: 0;
    flex-grow: 0;
  }
}
</style>
