export default class Listener {
  constructor(socket, emitter) {
    this.socket = socket;
    this.emitter = emitter;

    this.register();
  }

  register() {
    this.socket.hook((name, data) => {
      this.emitter.emit(name, data);
    });
  }
}
