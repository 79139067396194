import Socket from '@kim5257/polling-comm-client';
import Emitter from './emitter';
import Listener from './listener';
import Mixin from './mixin';

export default class VuePollingComm {
  constructor({ host, vuex }) {
    this.socket = new Socket(host, { waitTimeout: 60000 });
    this.emitter = new Emitter(vuex);
    this.listener = new Listener(this.socket, this.emitter);
  }

  install(Vue) {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$socket = this.socket;
    // eslint-disable-next-line no-param-reassign
    Vue.$socket = this.socket;

    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$vueSocket = this;
    // eslint-disable-next-line no-param-reassign
    Vue.$vueSocket = this;

    Vue.mixin(Mixin);
  }
}
