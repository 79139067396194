<template>
  <layout-dashboard-card
    title="총 이벤트 건수"
    :value="statEvents.total[0]"
    prepend-string="오늘"
    append-string="건"
    :icon="icon"
    :width="350"
    :height="180">
    <template v-slot:extension>
      <div class="card-history-list d-flex justify-center">
        <table>
          <tr
            v-for="idx in 3"
            :key="`value_${idx}`">
            <td>{{ idx }}일전</td>
            <td class="card-history-list-value">
              {{ statEvents.total[idx] }} 건
            </td>
          </tr>
        </table>
      </div>
    </template>
  </layout-dashboard-card>
</template>

<script>
import LayoutDashboardCard from '@/components/card/LayoutAccentCard.vue';
import Icon from '@/assets/icon_08.svg';
import { mapGetters } from 'vuex';

export default {
  name: 'CardTotalEvents',
  components: {
    LayoutDashboardCard,
  },
  data: () => ({
    icon: Icon,
  }),
  computed: {
    ...mapGetters({
      statEvents: 'stat/events',
    }),
  },
};
</script>

<style lang="scss" scoped>
.card-history-list {
  color: #9a9a9a;

  p {
    margin-bottom: 0;
  }

  table {
    .card-history-list-value {
      text-align: right;
      width: 48px;
    }
  }
}
</style>
