<template>
  <layout-card
    v-bind="$props">
    <template v-slot:body>
      <div class="d-flex flex-row">
        <div
          class="pr-2 pt-4 pb-4">
          <v-avatar
            color="#e4eff1"
            size="88px">
            <img
              class="pa-3"
              alt="icon"
              :src="icon"/>
          </v-avatar>
        </div>
        <div
          class="pa-2 d-flex flex-grow-1">
          <accent-value
            :value="value"
            :prepend-string="prependString"
            :append-string="appendString">
            <template v-slot:extension>
              <slot name="extension">
              </slot>
            </template>
          </accent-value>
        </div>
      </div>
    </template>
  </layout-card>
</template>

<script>
import LayoutCard from '@/components/card/LayoutCard.vue';
import AccentValue from '@/components/output/AccentValue.vue';

export default {
  name: 'LayoutAccentCard',
  components: {
    LayoutCard,
    AccentValue,
  },
  props: {
    title: {
      type: String,
      default: 'Title',
    },
    showMore: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    prependString: {
      type: String,
      default: null,
    },
    appendString: {
      type: String,
      default: null,
    },
    height: {
      type: Number,
      default: null,
    },
    width: {
      type: Number,
      default: null,
    },
    minWidth: {
      type: Number,
      default: null,
    },
  },
  computed: {
    value() {
      return this.$attrs.value;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
