export default class Emitter {
  constructor(vuex = {}) {
    this.store = vuex.store;
    this.actionPrefix = vuex.actionPrefix ? vuex.actionPrefix : 'SOCKET_';
    this.mutationPrefix = vuex.mutationPrefix;
    this.listeners = new Map();
  }

  addListener(event, callback, component) {
    if (!this.listeners.has(event)) {
      this.listeners.set(event, []);
    }

    this.listeners.get(event).push({ callback, component });
  }

  removeListener(event, component) {
    if (this.listeners.has(event)) {
      const listeners = this.listeners.get(event)
        .filter((listener) => (listener.component !== component));

      if (listeners.length > 0) {
        this.listeners.set(event, listeners);
      } else {
        this.listeners.delete(event);
      }
    }
  }

  emit(event, data) {
    if (this.listeners.has(event)) {
      this.listeners.get(event).forEach((listener) => {
        listener.callback.call(listener.component, data);
      });
    }

    this.dispatchStore(event, data);
  }

  dispatchStore(event, args) {
    // eslint-disable-next-line no-underscore-dangle
    if (this.store && this.store._actions) {
      let prefixedEvent = `${this.actionPrefix}${event}`;

      // eslint-disable-next-line no-underscore-dangle
      Object.keys(this.store._actions).forEach((key) => {
        const action = key.split('/').pop();

        if (action === prefixedEvent) {
          this.store.dispatch(key, args);
        }
      });

      if (this.mutationPrefix) {
        prefixedEvent = `${this.mutationPrefix}${event}`;

        // eslint-disable-next-line no-underscore-dangle
        Object.keys(this.store._mutations).forEach((key) => {
          const mutation = key.split('/').pop();

          if (mutation === prefixedEvent) {
            this.store.commit(key, args);
          }
        });
      }
    }
  }
}
